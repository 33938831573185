<template>
  <!-- 代理商管理 -->
  <div class="agentManage">
    <!-- 板块中心区域 -->
    <div class="agentManage_mid">
      <div class="agentManage_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item,index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">客户查询</span>
              <el-input v-model="datas.stxt" placeholder="请输客户名称" @input="checkUser"></el-input>
            </template>
            <!-- 所属地区 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">所属地区</span>
              <el-cascader size="large" :options="areaObj" v-model="selectedOptions" @change="handleChange"
                style="margin:0 1.45vw 0 0"></el-cascader>
            </template>
            <!-- 代理级别 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">代理级别</span>
              <el-select v-model="datas.level" placeholder="请选择" @change="checkLevel">
                <el-option v-for="item in options2" :key="item.id" :label="item.label" :value="item.value2">
                </el-option>
              </el-select>
            </template>
          </div>
          <!-- Element UI--Button按钮 -->
          <div class="btns">
            <el-button type="primary" style="margin-right: 1vw;background-color: #0666F5;"
              @click="formShow.isshow = true">添加代理商</el-button>
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <lists :allData="allData" @shareSize="shareSize" @sharePage="sharePage" :loading="loading"></lists>
        <forms :formShow='formShow'></forms>
      </div>

      <!-- 公司的基本信息 -->
      <div class="firm_inform">

      </div>
    </div>
  </div>
</template>

<script>

// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
// 引入中国地图数据
import { provinceAndCityData, CodeToText } from 'element-china-area-data'
// 引入表单页
import forms from "./components/forms";
// 引入列表页
import lists from "./components/lists";
import { angentList } from "@/utils/api";
export default {

  components: {
    lists,//列表页
    forms,//表单页
  },
  computed: {
    ...mapState('agentManage', ['options1', 'options2', 'formShow'])
  },
  data () {
    return {
      areaObj: provinceAndCityData,
      selectedOptions: [],//所属地区
      datas: {
        cardid: '',//网关编号
        page: 1,
        size: 10,
        stxt: '',//模糊搜索-为空表示不搜索，可搜索主体id，主体名称，主体联系人和主体联系人电话
        prov: '',
        city: '',
        level: '',//代理级别
      },
      allData: {},
      loading: true
    };
  },

  mounted () {
    this.getList()
  },

  methods: {
    // 获取代理商列表
    getList () {
      this.loading = true
      angentList(this.datas).then(res => {
        this.allData = res.data;
        this.loading = false
      }).catch(err => {
        return console.log(err)
      })
    },
    //客户查询
    checkUser () {
      this.getList()
    },
    // 代理级别
    checkLevel () {
      this.getList()
    },

    // 所属城市选择
    handleChange () {
      let location = '';
      for (let i = 0; i < this.selectedOptions.length; i++) {
        location += CodeToText[this.selectedOptions[i]];
      }
      let sheng = location.indexOf('省')
      // 省/市的下标
      if (sheng != -1) {//第一个地址是省
        let provInd = location.search('省');
        this.datas.prov = location.slice(0, provInd + 1);
        this.datas.city = location.slice(provInd + 1);
      } else {//第一地址是市
        let provInd = location.search('市');
        this.datas.prov = location.slice(0, provInd + 1);
        this.datas.city = location.slice(provInd + 1);
      }
      console.log(this.datas.prov)
      this.getList()
    },
    // 分页--size
    shareSize (size) {
      this.datas.size = size
    },
    sharePage (page) {
      this.datas.page = page
    }
  },
};
</script>

<style  scoped>
.agentManage {
  height: 100%;
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}
.agentManage .agentManage_mid {
  width: 100%;
  height: 100%;
}
/* 头部--开始 */
.agentManage_mid .agentManage_header {
  width: 100%;
  min-height: 134px;
  background-color: #fff;
}
.agentManage_header .title {
  display: flex;
  align-items: flex-end;
}
.agentManage_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}
.agentManage_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}
.options {
  height: 7.2vh;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}
.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}
.btns {
  margin-top: 25px;
}
/* 头部--结束 */

/* 表格区域--开始 */
.agentManage_mid .gateway_form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 1vh;
}
/* 表格区域--结束 */

.lists /deep/ .el-pagination {
  align-items: normal;
  margin-top: 14px;
  height: 50px !important;
}
</style>