<template>
  <!-- 代理商管理-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" :max-height="tableHeight" v-loading="loading">
        <!-- 索引序号 -->
        <el-table-column type="index" width="80" fixed></el-table-column>
        <!-- 编号 -->
        <el-table-column prop="cardid" label="编号" min-width="200">
        </el-table-column>
        <!-- 代理商 -->
        <el-table-column prop="gname" label="代理商" min-width="200">
        </el-table-column>
        <!-- 所属地区 -->
        <el-table-column prop="area" label="所属地区" min-width="200">
          <template #default="scope">{{ ((scope.row.prov || '') + (scope.row.city || '')) || '--' }}</template>
        </el-table-column>
        <!-- 代理级别 -->
        <el-table-column prop="dlevel" label="代理级别" min-width="200">
          <template #default="scope">{{ dlevel[scope.row.dlevel] || '--' }}</template>
        </el-table-column>
        <!-- 负责人 -->
        <el-table-column prop="guser" label="负责人" min-width="200">
        </el-table-column>
        <!-- 代理合约 -->
        <!-- <el-table-column prop="agencyContract" label="代理合约" min-width="200">
          <template slot-scope="scope">
            <el-link type="primary" v-for="(item,index) in scope.row.hetong " :key="index" :href="item">查看合约<i class="el-icon-view el-icon--right"></i> </el-link>
          </template>
        </el-table-column> -->
        <!-- 代理有效期 -->
        <el-table-column prop="stime" label="代理有效期" sortable min-width="200">
          <template #default="scope">{{ scope.row.stime || '--' }}</template>
        </el-table-column>
        <!-- 创建日期 -->
        <el-table-column prop="time" label="创建日期" min-width="200">
          <template #default="scope">{{ scope.row.time || '--' }}</template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="详情" fixed="right" min-width="200">
          <template slot-scope="scope">
            <el-link type="primary" @click="delAngent(scope.row)" style="margin-right:15px">删除</el-link>
            <el-link type="primary" @click="jumpToDetail(scope.row)" style="margin-right:15px">详情</el-link>
            <el-link type="primary" :href="scope.row.gurl" target="blank" style="margin-right:15px">公司信息</el-link>
          </template>
        </el-table-column>
        <div slot="empty" style="text-align:left;">
          <el-empty description="哎呀，你是不是忘记查询啦！" />
        </div>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page.sync="page" :page-sizes="pages" :page-size="allData.size"
        layout="total, prev, pager, next,sizes,jumper" :total="total">
      </el-pagination>
    </template>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { agentDel } from "@/utils/api";
export default {
  props: ['allData','loading'],
  components: {
  },

  data () {
    return {
      obj: {
        isShow: false
      },
      page: 1,//当前页数
      tableData: [],
      total: 0,
      pages: [10, 20, 30, 50],
      heyue: {},
      dlevel: ['全国','省份','市级','区级'],
      tableHeight: 0
    };
  },
  computed: {
  },
  watch: {
    allData (nVal) {
      this.tableData = nVal.data;
      this.total = nVal.total;
      let area = '';
      let arr = [];
      this.tableData.map(item => {
        area = item.prov + item.city;
        this.$set(item, "area", area)
        // 逗号的下标
        let indx = item.hetong.search(',')
        if (indx == -1) {//说明就一个地址
          item.hetong = JSON.parse(item.hetong)
        } else {
          let idArr = item.hetong.split(',');
          idArr.map(ele => {
            ele = JSON.parse(ele);
            arr.push(ele);
          });
          item.hetong = arr;
        }
      });
    }
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300;
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },
  methods: {
    // 查看
    // chaKan(val){
    //   this.obj.isShow = true
    //   this.heyue = val
    // },
    // 删除代理商
    delAngent (val) {
      this.$confirm('此操作将永久删除该运营商, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        // 删除代理商
        agentDel({ id: val.id }).then(res => {
          if (res.data.code == 1) {
            console.log(res)
          }
          // 刷新数据
          this.$parent.getList()
        }).catch(err => {
          return console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      this.$emit('shareSize', val)
    },
    handleCurrentChange (val) {
      this.$emit('sharePage', val)
    },
    // 跳转至详情页
    jumpToDetail (inform) {
      // 将需要的传递的信息存储到sessionStorage
      let informs = JSON.stringify(inform)
      sessionStorage.setItem('informs', informs);
      this.$router.push({ path: '/agentDetail' })
    }
  },
};
</script>

<style  scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}
.lists {
  height: 100%;
  position: relative;
  background-color: #fff;
}
.lists .el-pagination {
  position: absolute;
  height: 60px;
  bottom: 100px;
  background-color: #fff;
  right: 0;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
</style>