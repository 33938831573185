<template>

  <!-- 添加代理商 -->
  <div class="addAgent">
    <el-dialog title="添加代理商" :visible.sync="formShow.isshow" :close-on-click-modal="false" @close='closeDialog'>
      <!-- 内部dialog -->
      <el-dialog width="40%" title="地图" :visible.sync="isMap.isshow" append-to-body :close-on-click-modal="false"
        class="smaps">
        <!-- 搜索页面 -->
        <div id="search_ipt">
          <searchs></searchs>
        </div>
        <!-- map地图页面 -->
        <maps :isMap="isMap" @getlocations="getlocations"></maps>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="isMap.isshow = false">确定</el-button>
        </div>
      </el-dialog>

      <h3 class="aInform">代理商信息</h3>
      <!-- 代理商信息 -->
      <div class="agentInform">
        <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm">
          <!-- 营业执照号 -->
          <el-form-item label="营业执照号" class="redxing">
            <el-input v-model="form.cardid" placeholder="请输入18位营业执照号"></el-input>
          </el-form-item>
          <!-- 企业名称 -->
          <el-form-item label="企业名称" class="redxing">
            <el-input v-model="form.gname"></el-input>
          </el-form-item>
          <!-- 主体链接 -->
          <el-form-item label="主体链接" class="redxing">
            <el-input v-model="form.gurl"></el-input>
          </el-form-item>
          <!-- 企业性质 -->
          <el-form-item label="企业性质" class="redxing">
            <el-select v-model="form.gtype" style="width:100%">
              <el-option :label="item.label" :value="item.value" v-for="item in companies" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- 负责人 -->
          <el-form-item label="负责人" class="redxing">
            <el-input v-model="form.guser"></el-input>
          </el-form-item>
          <!-- 联系电话 -->
          <el-form-item label="联系电话" class="redxing">
            <el-input v-model="form.gphone"></el-input>
          </el-form-item>
          <!-- 负责人 -->
          <el-form-item label="紧急联系人" >
            <el-input v-model="form.gjuser"></el-input>
          </el-form-item>
          <!-- 联系电话 -->
          <el-form-item label="紧急联系电话" >
            <el-input v-model="form.gjphone"></el-input>
          </el-form-item>
          <!-- 邮箱 -->
          <el-form-item label="邮箱" class="redxing">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <!-- 所在区域 -->
          <el-form-item label="所在区域" class="area redxing">
            <el-cascader size="large" :options="options" v-model="form.areaArr" @change="handleChange"
              style="width:100%;">
            </el-cascader>
          </el-form-item>
          <!-- 代理区域 -->
          <el-form-item label="代理区域" class="address redxing">
            <el-cascader size="large" :options="options" v-model="form.detailArr" @change="handleChangeDetail"
              style="width:100%;">
            </el-cascader>
          </el-form-item>
          <!-- 地图 -->
          <el-form-item class="maps">
            <el-input v-model="form.addr" placeholder="请输入详细地址" suffix-icon='el-icon-map-location'></el-input>
            <div class="map_ipt" @click="isMap.isshow = true"></div>
          </el-form-item>
          <!-- 代理商类别 -->
          <!-- <el-form-item label="代理商类别" class="agent_sorts redxing">
            <el-select v-model="form.type" style="width:100%;">
              <el-option :label="item.label" :value="item.value" v-for="item in agentTypes" :key="item.id"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- 代理级别 -->
          <el-form-item label="代理级别" class="agent_level redxing">
            <el-select v-model="form.dlevel" placeholder="请选择代理级别 " style="width:100%;">
              <el-option :label="item.label" :value="item.value" v-for="item in levels" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="代理开始" class="times redxing">
            <el-date-picker v-model="form.atime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择开始代理日期时间">
            </el-date-picker>
            <!-- <el-checkbox v-model="form.isForever" @change="forever">永久</el-checkbox> -->
          </el-form-item>
          <el-form-item label="代理结束" class="times redxing">
            <el-date-picker v-model="form.stime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择结束代理日期时间">
            </el-date-picker>
            <!-- <el-checkbox v-model="form.isForever" @change="forever">永久</el-checkbox> -->
          </el-form-item>

          <!-- 签订日期 -->
          <el-form-item label="签订日期" class="sign_data redxing">
            <el-date-picker v-model="form.qtime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间"
              style="width:100%;">
            </el-date-picker>
          </el-form-item>
          <!-- 到期时间 -->
          <el-form-item label="到期时间" class="sign_data redxing">
            <el-date-picker v-model="form.dtime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择到期时间"
              style="width:100%;">
            </el-date-picker>
          </el-form-item>
          <!-- 合同上传 -->
          <el-form-item label="合同上传" class="contract_upload redxing">
            <el-upload class="avatar-uploader" :show-file-list="true" :on-change="documentUpload"
              :action="$baseUrl + '/api/upload/annex/tract/up'" :file-list="fileList" :auto-upload='false'
              :limit="3" :on-exceed="handleExceed" :on-preview="clickFile">
              <el-button plain icon="el-icon-upload2">文件上传</el-button>
              <div slot="tip" class="el-upload__tip">*仅支持png、jpg、jpeg、pdf格式</div>
            </el-upload>
          </el-form-item>
          <!-- 证件上传 -->
          <el-form-item label="证件上传" class="contract_upload1 redxing">
            <!-- 图片上传1 -->
            <el-upload class="avatar-uploader2" :show-file-list="false" :on-change="uploadFile"
              :action="$baseUrl + '/api/upload/annex/tract/up'" :auto-upload='false'>
              <div class="documents">
                <img v-if="file1Url" :src="file1Url" alt="" style="width:84px;height:47px;">
                <i v-else class="el-icon-circle-plus"></i>
              </div>
            </el-upload>
            <!-- 图片上传2 -->
            <el-upload class="avatar-uploader2" :show-file-list="false" :on-change="aptitudeUpload"
            :action="$baseUrl + '/api/upload/annex/tract/up'" multiple :auto-upload='false'>
              <div class="documents">
                <img v-if="file2Url" :src="file2Url" alt="" style="width:84px;height:47px;">
                <i v-else class="el-icon-circle-plus"></i>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <!-- 授权信息 -->
      <h3 class="authorized_title">授权信息</h3>
      <div class="authorized_inform">
        <el-form :model="form" ref="ruleForm" label-width="100px" class="ruleForm">
          <!-- 连邦负责人 -->
          <el-form-item label="销售员" class="redxing">
            <el-input v-model="form.saler"></el-input>
          </el-form-item>
          <!-- 连邦负责人电话 -->
          <el-form-item label="销售员联系方式" class="lbPhone redxing">
            <el-input v-model="form.salephone"></el-input>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item label="备注" class="lbPhone">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm()">取 消</el-button>
        <el-button type="primary" @click="addAgents">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { provinceAndCityDataPlus, CodeToText, regionDataPlus } from 'element-china-area-data';
import { msg } from "../../../common/public";
// 引入表单验证
import { checkInpput, uploadImg, fileuploads } from "@/assets/js/public";
// 引入api
import { addAngent, fileUpload } from "@/utils/api";

// 引入地图和输入框
import maps from "../../customerInformation/components/map.vue";
import searchs from "../../customerInformation/components/search.vue";
export default {
  props: ['formShow'],
  components: {
    maps,
    searchs
  },
  data () {
    return {
      options: provinceAndCityDataPlus,
      // optionsDetail:regionDataPlus,
      isDisable: false,//是否永久
      file1Url: '',//证件上传--证件1
      file2Url: '',//证件上传--证件2
      fileList: [],
      isMap: {
        isshow: false
      },
      form: { //代理商管理的表单
        cardid: '',//营业执照号
        gname: '',//企业名称
        gurl: '',//主体链接
        gtype: '',//企业性质
        guser: '',//负责人或联系人
        gphone: '',//联系方式
        gjuser: '',//紧急联系人
        gjphone: '',//紧急联系人方式
        email: '',//邮箱
        prov: '',//省份
        city: '',//城市
        areaArr: [],//省市选择框
        detailArr: [],//省市区的选择框
        addr: '',//详细地址
        annex: [],//合同数组
        tract: [],//证件数组
        saler: '',//销售员
        salephone: '',//销售员电话
        lon: '',//经度
        lat: '',//维度
        atime: '',//代理开始的时间
        stime: "",//代理结束的时间
        dtime: '',//到期时间
        isForever: false,//是否永久
        type: '',//代理商类别
        dlevel: '',//代理商级别
        qtime: '',//签订日期
        dprov: '',//代理的省份
        dcity: '',//代理的城市
        configure: '',//配置账号
        login: '',//登录账号
        remark: '',//备注信息
      },
      timer: null,
    };
  },
  computed: {
    ...mapState('agentManage', ['companies', 'agentTypes', 'levels', 'agentLevels'])
  },
  mounted () {

  },

  methods: {
    cancel () {
      this.isMap.isshow = false
    },
    // 添加代理商
    addAgents () {
      // 表单验证
      checkInpput(this.form) && addAngent({...this.form, annex: JSON.stringify(this.form.annex), tract: JSON.stringify(this.form.tract)}).then(res => {
        if (res.code == 1) {
          msg(res.msg, 'success');
          this.timer = setTimeout(() => {
            this.formShow.isshow = false;
          }, 1500)
        } else if (res.code == 2) {
          msg(res.msg, 'warning');
          return
        } else {
          msg(res.msg, 'error')
          return
        }
        // 刷新数据
        this.$parent.getList();
        // 重置表单
        this.empty()
      }).catch(err => {
        return console.log(err)
      })
    },
    // map组件传递的经纬度和地址
    getlocations (arr) {
      this.form.addr = arr[1];
      this.form.lon = arr[0][0];//经度
      this.form.lat = arr[0][1];//纬度
    },
    //最多只能添加三份合同文件
    handleExceed () {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 点击合同上传中的文件
    clickFile (file) {
      window.open(file.url, false)
    },
    // 重置表单信息
    resetForm () {
      this.empty()
      this.formShow.isshow = false;
      return false
    },
    // 合同上传
    documentUpload (file) {
      let fileObj = fileuploads(file);
      let { name } = fileObj
      fileUpload({ file: fileObj, uid: 2, id: 1, type: 1 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.annex.push({name,url})
          let obj = { 'name': name, 'url': `${this.$URL}${url}` }
          this.fileList.push(obj)
        }
      }).catch(err => {
        return console.log(err)
      })
    },

    // 证件上传--证件上传1
    uploadFile (file) {
      let fileObj = uploadImg(file);
      this.file1Url = URL.createObjectURL(fileObj);
      fileUpload({ file: fileObj, uid: 2, id: 1, type: 2 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.tract.push(url);
        }
      }).catch(err => {
        return console.log(err)
      })
    },
    aptitudeUpload (file) {
      let fileObj = uploadImg(file);
      this.file2Url = URL.createObjectURL(fileObj);
      fileUpload({ file: fileObj, uid: 2, id: 1, type: 2 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.tract.push(url);
        }
      }).catch(err => {
        return console.log(err)
      })
    },
    // 所在区域
    handleChange () {
      let location = "";
      if (this.form.areaArr[0] == '') { //说明第一个地址选择了全部,需要提示他选择地址
        msg('请选择你所在省', 'error')
        return false
      };
      if (this.form.areaArr[1] == '') { //在选择第二项地址时选择了全部，未详细 提醒
        msg('请选择你所在市', 'error')
        return false
      }
      // 将地区码转话为地址
      for (let i = 0; i < this.form.areaArr.length; i++) {
        location += CodeToText[this.form.areaArr[i]];
      }
      let sheng = location.indexOf('省')
      // 省/市的下标
      if (sheng != -1) {//第一个地址是省
        let provInd = location.search('省');
        this.form.prov = location.slice(0, provInd + 1);
        this.form.city = location.slice(provInd + 1);
      } else {//第一地址是市
        let provInd = location.search('市');
        this.form.prov = location.slice(0, provInd + 1);
        this.form.city = location.slice(provInd + 1);
      }
    },
    // 代理区域
    handleChangeDetail () {
      let location = '';//获取选择的地址
      if (this.form.detailArr[0] == '') { //说明第一项选择了全部  提醒选择详细地址
        msg('请选择你所在省', 'error')
        return false
      }
      if (this.form.detailArr[1] == '') {
        msg('请选择你所在市的详细地址', 'error')
        return false
      }
      // 将地区码转话为地址
      for (let i = 0; i < this.form.detailArr.length; i++) {
        location += CodeToText[this.form.detailArr[i]];
      }
      let sheng = location.indexOf('省')
      // 省/市的下标
      if (sheng != -1) {//第一个地址是省
        let provInd = location.search('省');
        this.form.dprov = location.slice(0, provInd + 1);
        this.form.dcity = location.slice(provInd + 1);
      } else {//第一地址是市
        let provInd = location.search('市');
        this.form.dprov = location.slice(0, provInd + 1);
        this.form.dcity = location.slice(provInd + 1);
      }
    },
    // 是否永久
    forever () {
      if (this.form.isForever) { //为真 -- 永久
        this.form.dtime = '2999-01-01 00:00:00'
        this.isDisable = true;
      } else {
        this.isDisable = false;
        this.form.dtime = ''
      }
    },
    // 表单置空
    empty () {
      this.form = {
        cardid: '',
        gname: '',
        gurl: '',
        gtype: '',
        guser: '',
        gphone: '',
        gjuser: '',
        gjphone: '',
        email: '',
        prov: '',
        city: '',
        areaArr: [],
        detailArr: [],
        addr: '',
        annex: [],
        tract: [],
        saler: '',
        salephone: '',
        lon: '',
        lat: '',
        atime: '',
        stime: "",
        dtime: '',
        isForever: false,
        type: '',
        dlevel: '',
        qtime: '',
        dprov: '',
        dcity: '',
        configure: '',
        login: '',
        remark: '',
      };
    },
    //点X关闭dialog
    closeDialog () {
      // this.empty();
    }

  },
};
</script>

<style  scoped>
/* .el-dialog__wrapper */
/*滚动条样式*/
.el-dialog__wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.el-dialog__wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.el-dialog__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

/* 地图的可视高度 */
.smaps /deep/ .el-dialog {
  margin-top: 3vh !important;
}
/* 搜素页面 */
#search_ipt {
  position: absolute;
  z-index: 999;
  /* border: 1px solid #333; */
  position: absolute;
  top: 100px;
  left: 38px;
}
.addAgent /deep/ .el-dialog {
  margin-top: 1vh !important;
}
.addAgent /deep/ .el-dialog__title {
  font-family: "SHSCN_Bold";
}
/* dialog的宽度 */
.addAgent /deep/ .el-dialog {
  width: 70%;
}
/* h3标题的样式 */
.addAgent .aInform {
  font-family: "SHSCN_Bold";
  margin: 0 0 3% 1%;
  color: #171717;
}
/* 代理商信息 */
.agentInform {
  width: 100%;
  margin: -2% auto;
}
/* dialog主体部分的内边距 */
::v-deep .el-dialog__body {
  padding: 30px;
}
.agentInform .el-form {
  display: grid;
  grid-template-columns: 33% 33% 34%;
}
/* 所在区域的样式 */
.area /deep/ .el-form-item__content {
  display: flex;
}

.area /deep/ .el-input:nth-child(1) {
  margin-right: 4.9vw;
}
/* 详细地址的样式 */
.address /deep/ .el-form-item__content {
  display: flex;
}
.map_ipt {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

/* 生效时间的样式 */
.times /deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
/* 文件上传 */
.contract_upload /deep/ .el-upload__tip {
  font-size: 12px;
  color: #b2b2b2;
  font-family: "SHSCN_Regular";
  margin-top: 0px;
}
.contract_upload /deep/ .el-button {
  border: 1px solid transparent;
  position: relative;
  top: -6px;
  padding: 12px 2px;
}
.contract_upload .el-button /deep/ span {
  color: #0666f5;
  font-size: 16px;
}
.contract_upload .el-button /deep/ i {
  color: #0666f5;
  font-size: 16px;
}
.contract_upload /deep/ img {
  margin: 0 0 0.7vh 0;
}
.contract_upload /deep/ .el-form-item__content {
  line-height: 0;
  margin: 1vh 0 0 0;
}
/* 证件上传 */
.contract_upload1 /deep/ .el-form-item__content {
  display: flex;
}
.avatar-uploader2 {
  margin-right: 20px;
}
.contract_upload1 .documents {
  width: 84px;
  height: 47px;
  background-color: #f1f3f8;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contract_upload1 .documents .el-icon-circle-plus {
  font-size: 0.9vw;
  color: #4888ff;
}

/* 授权信息 */
/* 授权信息的标题 */
.authorized_title {
  font-family: "SHSCN_Bold";
  margin: 5% 0 3% 1%;
  color: #171717;
}
/* 授权信息的表单区域 */
.authorized_inform {
  width: 100%;
}
.authorized_inform .el-form {
  display: grid;
  grid-template-columns: 33% 33% 34%;
}
.maps /deep/ .el-input__icon {
  color: #0666f5;
}
/* 连邦负责人电话 */
.lbPhone /deep/ .el-form-item__content {
  margin-left: 117px !important;
}
.lbPhone /deep/ .el-form-item__label {
  width: 117px !important;
}

/* 在label前加上* */
.redxing /deep/ .el-form-item__label::before {
  content: "*";
  color: red;
}

/* 多媒体查询 */
/* 当前最小屏幕位1920px时 */
@media screen and (min-width: 1920px) {
  /* 生效时间 */
  .times /deep/ .el-checkbox__input {
    margin: 0 -0.2vw 0 4vw !important;
  }
}
/* 当前分辨率的范围为   1600<screen<1920 */
@media screen and (min-width: 1600px) and (max-width: 1920px) {
  /* 详细地址 */
  /* 地图的样式 */
  .maps /deep/ .el-form-item__content {
    margin-left: 15% !important;
  }
  /* 生效时间 */
  .times /deep/ .el-checkbox__input {
    margin: 0 -0.2vw 0 0.5vw !important;
  }
}
/* 当前屏幕的分辨率最大为1600时 */
@media screen and (max-width: 1600px) {
  /* 地图的样式 */
  .maps /deep/ .el-form-item__content {
    margin-left: 20% !important;
  }
  /* 生效时间 */
  .times /deep/ .el-checkbox__input {
    margin: 0 -0.4vw 0 1vw !important;
  }
}
/* 屏幕分辨率小于1200 */
@media screen and (max-width: 1200px) {
  /* 地图的样式 */
  .maps /deep/ .el-form-item__content {
    margin-left: 45% !important;
  }
}
</style>